<template>
  <div id="draw-lines" class="">
    <svg id="svg" class="graph">
      <path
        v-if="lines.length > 0"
        v-for="line in lines"
        :d="line.d"
        :style="line.style"
        fill="transparent"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "draw-lines",
  props: { refreshLine: Boolean, chart: Object },
  data: function () {
    return {
      lines: [],
      branchColors: {}, // Almacena los colores por rama (PstParentID)
    };
  },
  methods: {
    refreshLines() {
      this.lines = [];
      this.addLine();
    },
    addLine() {
      this.lines = this.updateLines(this.chart, []);
    },
    updateLines(position, lines) {
      var svg = document.getElementById("svg");
      var xparent = document.getElementById("org");
      svg.style.width = xparent.offsetWidth + "px";
      svg.style.height = xparent.offsetHeight + 2 + "px";

      // Asignar color al nodo actual
      if (position.PstHierarchicalLevel === 1) {
        // Nivel 1: Color fijo (gris)
        position.color = "#b6b4b4";
      } else {
        // A partir del nivel 2: Color único por rama (PstParentID)
        if (!this.branchColors[position.PstID]) {
          let newColor;
          do {
            newColor = this.getRandomColor();
          } while (Object.values(this.branchColors).includes(newColor)); // Verificar si el color ya está en uso
          this.branchColors[position.PstID] = newColor;
        }
        position.color = this.branchColors[position.PstID];
      }

      var line;
      position.children.forEach((child) => {
        // Pasar el color del padre al hijo
        child.color = position.color;

        line = this.getLine(child);
        if (line) {
          lines.push(line);
          this.updateLines(child, lines); // Continuar con los hijos
        }
      });
      return lines;
    },
    getLine(position) {
      if (position.isFake) {
        return { d: "", style: "height: 150px;" };
      }

      var pos = this.getPosOfElement(position);
      if (!pos.parent) return null;

      var x = document.getElementById("org");
      var scale = x.offsetWidth
        ? 1 / (x.getBoundingClientRect().width / x.offsetWidth)
        : 1;

      const startX = Math.round(pos.parent.x + pos.parent.width / 2) * scale;
      const startY = Math.round(pos.parent.y + pos.parent.height) * scale;
      const endX = Math.round(pos.element.x + pos.element.width / 2) * scale;
      const endY = Math.round(pos.element.y) * scale;

      let d;
      let style = `stroke:${position.color || "#b6b4b4"};stroke-width:3`;

      const controlPointOffset = 40;
      if (
        position.PstHierarchicalLevel - position.parent.PstHierarchicalLevel >
        1
      ) {
        d = ` 
      M${startX} ${startY}
      L${startX} ${startY + 20}
      L${endX} ${startY + 20}
      V${endY}`;
      } else {
        d = `
      M${startX} ${startY}
      C${startX} ${startY + controlPointOffset}, ${endX} ${
          startY + controlPointOffset
        }, ${endX} ${endY}`;
      }

      return { d, style };
    },
    getPosOfElement(dept) {
      if (dept.PstID === undefined) {
        return { parent: null, element: null };
      }

      var parentElement = document.getElementById("ID_" + dept.parent.PstID);
      var childElement = document.getElementById("ID_" + dept.PstID);
      if (!parentElement || !childElement) return { parent: null };

      var pos = {
        parent: parentElement.getBoundingClientRect(),
        element: childElement.getBoundingClientRect(),
      };

      var chartpos = document.getElementById("org").getBoundingClientRect();

      if (pos.parent) {
        pos.parent.x = pos.parent.left - chartpos.left;
        pos.parent.y = pos.parent.top - chartpos.top;
      }
      pos.element.x = pos.element.left - chartpos.left;
      pos.element.y = pos.element.top - chartpos.top;
      return pos;
    },
    getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
  },
  watch: {
    refreshLine: function () {
      if (this.chart) this.refreshLines();
    },
  },
  mounted() {
    this.$nextTick().then(() => {
      this.$emit("loadLines");
    });
    this.refreshLines();
  },
};
</script>

<style scoped>
.graph {
  width: 100%;
  height: 800px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
}
</style>