<template>
  <div id="org" class="print-container">
    <show-dept
      v-if="chartV"
      :parent="chartV"
      :TypeNivel="TypeNivel"
      :level="0"
      :levels="levels"
      @up="up()"
      :managerNameView="managerNameView"
      :report="report"
    ></show-dept>
    <draw-lines
      @loadLines="loadLines()"
      :refreshLine="refreshLine"
      v-if="chartV"
      :chart="chartV"
    ></draw-lines>
  </div>
</template>

<script>
import ShowDept from "./ShowDep";
import DrawLines from "./DrawLines";
import OrgService from "./../../services/HumanResource/OrganizationLevelService";

export default {
  name: "Org",
  components: { ShowDept, DrawLines },

  data() {
    return {
      isLoading: false,
      showOrgChart: true,
      loadBox: false,
      refreshLine: false,
      orgArray: [],
      data: [],
      chartV: null,
      chart: null,
      people: [],
      nodes: [],
      config: {
        nodeWidth: 150,
        nodeHeight: 50,
        nodeSpacing: 20,
        direction: "TB",
      },
    };
  },
  props: {
    parent: { type: Object, default: {} },
    level: { type: Number, default: 0 },
    levels: { type: Array, default: () => [1, 2, 3, 4, 5, 6, 7, 8] },
    managerNameView: Boolean,
    id: 0,
    report: false,
    TypeWorker: { type: Number, default: 0 },
    TypePosition: { type: Number, default: 0 },
    TypeNivel: { type: Number, default: 0 },
  },
  methods: {
    onInit: function (panzoomInstance, id) {
      const container = document.getElementById(id);
      const { width: containerWidth, height: containerHeight } =
        container.getBoundingClientRect();

      const content = container.firstElementChild;
      const { width: contentWidth, height: contentHeight } =
        content.getBoundingClientRect();

      const offsetX = (containerWidth - contentWidth) / 2;
      const offsetY = (containerHeight - contentHeight) / 2;

      panzoomInstance.setMaxZoom(1);
      panzoomInstance.setMinZoom(1);
      panzoomInstance.zoomAbs(0, 0, 1);

      panzoomInstance.moveTo(offsetX, offsetY);
    },
    processData(dept, orgArray) {
      let positionChild = 1;

      if (dept.PstHierarchicalLevel >= this.level) {
        dept.OrgIsAsistent = dept.OrgIsAsistent == 1 ? true : false;
        dept.showChildren = true;
        orgArray.push(dept);

        // Inicializar el array de posiciones de hijos en el parent si no existe
        if (!dept.childPositions) {
          dept.childPositions = [];
        }

        dept.children = dept.GenPositionChild || [];
        dept.children.forEach((c) => {
          c.parent = dept;
          c.position = positionChild;

          // Añadir la posición del hijo al array del parent
          dept.childPositions.push({
            childId: c.PstID,
            position: positionChild,
          });

          // Guardar la posición del padre como hijo en el hijo
          c.parentPositionAsChild = positionChild;

          positionChild++;
          c.parentHierarchy = dept.PstHierarchicalLevel;

          this.processData(c, orgArray);
        });
      }

      return { dept: dept, orgArray: orgArray };
    },
    loadLines(v) {
      this.$nextTick().then(() => {});
    },
    up() {
      this.refreshLine = !this.refreshLine;
      this.$nextTick(function () {
        this.$emit("upOrg");
        this.$emit("printImg");
      });
    },
    load() {
    if (this.isLoading) return; // Evitar múltiples llamadas simultáneas
    this.isLoading = true;

    let obj = this.parent;

    OrgService.listLevelOrg(obj)
      .then((r) => {
        if (r.data) {
          this.chart = r.data;
          this.chartV = this.processData(this.chart, []).dept;

          if (!this.chartV) {
            console.error("No se pudo cargar el organigrama.");
            return;
          }

          this.$emit("up");
        } else {
          console.error("Datos del organigrama no disponibles.");
        }
      })
      .catch((error) => {
        console.error("Error al cargar el organigrama:", error);
      })
      .finally(() => {
        this.isLoading = false; // Liberar la bandera
      });
  },
    exportChartAsImage() {
      const orgElement = document.getElementById("org");
      html2canvas(orgElement).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 10, 10, 180, 160);
        pdf.save("org-chart.pdf");
      });
    },
  },
  created() {
    this.load();
  },
  updated() {
    //emitir el evento de actualización solo 1 vez por llamada
    if (this.chartV) {
      
      this.load();
    }
  },
  watch: {
    TypeWorker() {},
    TypePosition() {},
    id: function (val, val2) {},
    level: function (val, val2) {},
  },
  mounted() {},
};
</script>

<style scoped>
#print-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

#org {
  position: relative;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  min-width: 300px;
  -ms-user-select: none;
  user-select: none;
  background-color: white;
  cursor: grab;
  height: auto !important;
}
.chart-container {
  margin: 0px;
  display: inline-block;
}

.arrow {
  font-size: 30px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin: -8px;
}

.down {
  cursor: zoom-in;
}

.up {
  cursor: zoom-out;
}

.panzom {
  height: auto !important;
  width: 100%;
}

@media print {
  #print-container {
    transform: scale(1);
    transform-origin: top left;
  }
}
</style>