<template>
  <div class="org-chart">
    <div v-for="level in levels" :key="level" class="level">
      <div
        v-for="child in getChildrenByLevel(level)"
        :key="child.PstID"
        :style="getChildStyle(child)"
      >
        <dept-box
          :managerNameView="managerNameView"
          :department-data="child"
          :level="child.PstHierarchicalLevel"
          :type="'column'"
          :style="getChildStyle(child)"
        ></dept-box>
      </div>
    </div>
    <div v-if="unassignedChildren.length > 0" class="unassigned-level">
      <div
        v-for="child in unassignedChildren"
        :key="child.PstID"
        class="dept-container"
      >
        <dept-box
          :managerNameView="managerNameView"
          :department-data="child"
          :level="getLevel(child)"
          type="column"
        ></dept-box>
      </div>
    </div>
    <draw-lines
      @loadLines="loadLines()"
      :refreshLine="refreshLine"
      v-if="chartV"
      :chart="chartV"
    ></draw-lines>
  </div>
</template>
<script>
import DeptBox from "./OrgBox";
import DrawLines from "./DrawLines";

export default {
  name: "ShowDept",
  components: { DeptBox, DrawLines },
  props: {
    managerNameView: Boolean,
    parent: {
      type: Object,
      required: true,
      default: null,
    },
    level: {
      type: Number,
      required: true,
      default: 0,
    },
    TypeNivel: {
      type: Number,
      required: true,
      default: 0,
    },
    levels: {
      type: Array,
      required: true,
      default: () => [1, 2, 3, 4, 5, 6, 7, 8],
    },
  },

  data() {
    return {
      showChildren: false,
      nivel: 0,
      refreshLine: false,
      chartV: null,
    };
  },
  watch: {
    parent() {
      this.$nextTick(function () {
        this.$emit("up");
      });
    },
    managerNameView() {
      this.$nextTick(function () {
        this.$emit("up");
      });
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.$emit("up");
    });
  },

  computed: {
    hierarchyChildren() {
      let children = this.parent.children.filter((e) => e.OrgIsAsistent == 0);
      this.nivel = this.level + 1;
      return children;
    },
    staffChildren() {
      var staff = this.parent.children.filter((e) => e.OrgIsAsistent == 1);
      var result = [];
      staff.forEach((e, i) => {
        if (i % 2 === 0) result.push({ left: e, right: null });
        else result[result.length - 1].right = e;
      });
      return result;
    },
    unassignedChildren() {
      return this.getAllChildren(this.parent).filter(
        (child) => child.PstHierarchicalLevel === 0 && !child.parent
      );
    },
  },
  methods: {
    getChildrenByLevel(level) {
      if (level === 1) {
        return [this.parent];
      }

      // Obtener todos los hijos del nivel actual
      const children = this.getAllChildren(this.parent).filter((child) => {
        if (child.PstHierarchicalLevel === 0 && child.parent) {
          return child.parent.PstHierarchicalLevel === level - 1;
        }
        return child.PstHierarchicalLevel === level;
      });

      // Evitar procesar nodos ficticios y manejar correctamente los niveles
      const validChildren = children.filter((child) => !child.isFake);

      // Centrar hijos debajo de sus padres
      const maxItems = Math.max(
        ...this.levels.map((l) => validChildren.length)
      );
      return validChildren.map((child, index) => {
        const offset = Math.floor((maxItems - validChildren.length) / 2);
        child.gridPosition = index + offset;
        return child;
      });
    },

    getChildStyle(child) {
      const parent = child.parent;

      if (parent) {
        let marginPX = 0;
        let marginPY = 0;
        if (parent.GenPositionChild.length > 0) {
          marginPX = 5 * parent.GenPositionChild.length;
          marginPY = 8 * (parent.GenPositionChild.length);
        }
        return {
          margin: `${ '0px ' + marginPY}px`, // Espacio entre nodos
          //marginTop: `${marginPY}px`,
          //border: "2px solid red", // Estilo para los hijos (rojo)
        };
      }
      return {};
    },

    getParentStyle(child) {
      var count = 0;
      // Calcular el margen para centrar los hijos debajo del padre
      const marginPX = count > 0 ? 5 * count : 0;
      const marginPY = count > 0 ? 8 * count : 0;
      const top = 40;
      return {
        //padding: `0 ${top + marginPX}px`,
        //hacer flotar
        //margin: `${(marginPY) + 'px ' + marginPX}px`, // Espacio entre nodos
        //display: `flex`,
        //marginTop: `${top}px`,
        //border: `blue 5px solid`, // Estilo para los padres (azul)
      };
    },

    getColspan(child) {
      if (child.children && child.children.length > 0) {
        return child.children.length;
      }
      return 1;
    },

    getRowspan(child) {
      if (child.children && child.children.length > 0) {
        return 1;
      }
      return 1;
    },

    getLevel(child) {
      if (child.PstHierarchicalLevel === 0 && child.parent) {
        return child.parent.PstHierarchicalLevel + 1;
      }
      return child.PstHierarchicalLevel;
    },

    getAllChildren(parent) {
      let children = [];
      if (parent.children) {
        parent.children.forEach((child) => {
          if (!child.isFake && !children.some((c) => c.PstID === child.PstID)) {
            children.push(child);
            children = children.concat(this.getAllChildren(child));
          }
        });
      }
      return children;
    },

    loadLines(v) {
      this.$nextTick().then(() => {
        this.refreshLine = !this.refreshLine;
      });
    },
  },
};
</script>

<style scoped>
.level {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 50px; /* Espacio entre niveles */
  gap: 5px; /* Espacio entre nodos del mismo nivel */
}

.dept-container {
  gap: 5px; /* Espacio entre nodos */
  margin: auto 10px; /* Reducir el margin */
}

.unassigned-level {
  display: grid;
  grid-auto-flow: column;
  gap: 1px; /* Espacio entre nodos no asignados */
}

.children-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px; /* Ajusta el espacio entre padres e hijos */
}

.empty-space {
  height: 50px; /* Ajusta la altura del espacio vacío */
  display: flex;
  align-items: center;
  justify-content: center;
}

.line {
  width: 2px;
  height: 100%;
  background-color: #000;
  margin: 0 5px; /* Ajusta el espacio entre las líneas */
}
</style>